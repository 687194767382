// .breadcrumb
$breadcrumb-bg: transparent !default;
$breadcrumb-font-size: 80%;
$breadcrumb-padding-y: $spacer * 0.75 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-margin-bottom: $spacer !default;

$breadcrumb-item-padding: $spacer * 0.5 !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: quote("/") !default;
$breadcrumb-border-radius: $border-radius !default;