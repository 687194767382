$btn-border-radius: 8px;
$btn-height: 40px;

//.btn.btn-primary
$btn-primary-color: $app-color-primary-dark;
$btn-primary-background: linear-gradient(-18deg, $app-color-primary-dark 60%, transparent 50%);
$btn-primary-background-size: 300% 200%;
$btn-primary-background-position: 0 0;
$btn-primary-transition: background-position .3s ease-in-out, color .3s ease-in-out;

//.btn.btn-primary:hover
$btn-primary-hover-color: $app-color-white;
$btn-primary-hover-background: linear-gradient(-18deg, $app-color-primary-dark 60%, transparent 50%);
$btn-primary-hover-background-position: 99% 50%;
$btn-primary-hover-background-size: $btn-primary-background-size;

$btn-outline-primary-border: $app-color-primary;
$btn-outline-primary-hover-border: $app-color-primary-dark;
