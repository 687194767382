// Toasts container
$toasts-top: 15px;
$toasts-right: 15px;

// Toast item
$toasts-item-margin-top: 10px;
$toasts-item-border-width: 1px;
$toasts-item-border-style: solid;
$toasts-item-background-color: $body-bg;
$toasts-item-border-color: darken($toasts-item-background-color, 10%);
$toasts-item-padding-y: $spacer * 0.5;
$toasts-item-padding-left: $spacer;
$toasts-item-padding-right: $spacer * 2;
$toasts-item-min-width: 150px;
$toasts-item-max-width: 300px;
$toasts-item-box-shadow: $popover-box-shadow;
$toasts-item-font-size: 90%;
$toasts-item-transition: all 0.3s;
$toasts-item-transform: translate(500px, 0);

// Close button
$toasts-item-close-padding: 5px;
$toasts-item-close-color: $gray-400;
$toasts-item-close-hover-color: $black;
$toasts-item-close-font-size: 85%;

// Styled toasts
$toasts-types: ("error": $danger, "warning": $warning, "success": $success);

// Styled toast item
$toasts-item-styled-border-left-width: 3px;
$toasts-item-styled-border-left-style: solid;
$toasts-item-styled-background-color: $body-bg;
$toasts-item-styled-color: $body-color;