// .footer .content
$app-footer-content-background-color: #fff;
$app-footer-content-color: #000;
$app-footer-content-padding-y: $spacer;
$app-footer-content-padding-x: 0;

// .footer .border
$app-footer-border-background: $app-background-gradient;
$app-footer-copyright-text-align: center;

// .footer .copyright
$app-footer-copyright-font-size: 12px;
$app-footer-copyright-font-family: $font-family-sans-serif;