// .products-page .estimated-delivery-date
$products-page-estimated-delivery-date-font-size: 14px;
$products-page-estimated-delivery-date-font-style: italic;
$products-page-estimated-delivery-date-padding-bottom: $spacer;

// .products-page .note
$products-page-note-font-size: 14px;
$products-page-note-font-style: italic;
$products-page-note-padding-bottom: $spacer;
$products-page-note-color: $app-color-gray-dark;
$products-page-note-note-font-weight: normal;
$products-page-note-note-line-height: 16px;