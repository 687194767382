$collection-error-padding-y: $spacer;
$collection-error-padding-x: 0;
$collection-error-color: $danger;
$collection-error-font-size: 80%;

$collection-item-color: $black;

$collection-item-image-margin-top: 0;
$collection-item-image-margin-bottom: $spacer;
$collection-item-image-border-width: 2px;
$collection-item-image-border-style: solid;
$collection-item-image-border-color: transparent;

$collection-item-selected-img-border-width: $collection-item-image-border-width;
$collection-item-selected-img-border-style: $collection-item-image-border-style;
$collection-item-selected-img-border-color: $primary;

$collection-item-title-margin-top: 0;
$collection-item-title-margin-bottom: $spacer;
$collection-item-title-font-weight: bold;

$collection-item-description-font-size: 90%;
$collection-item-description-margin-top: 0;
$collection-item-description-margin-bottom: $spacer;