$approvals-list-min-width: 730px;

$approvals-list-column-padding: 7.5px;

// .approvals-list .column-name
$approvals-list-column-name-width: 80px;

// .approvals-list .column-order
$approvals-list-column-order-width: 175px;

// .approvals-list .column-details
$approvals-list-column-details-width: 195px;

// .approvals-list .column-editable
$approvals-list-column-editable-width: 215px;


// .approvals-list .column-cta
$approvals-list-column-cta-width: 250px;